<template>
  <div class="b-keterlambatan-form pb-5">
    <b-form @submit.prevent="doSubmit">
      <b-row no-gutters class="m-0">
        <b-col sm="12" class="p-3">
          <b-form-group label="Kantor Cabang" class="mb-3 f-13 bold" disabled>
            <b-input class="f-13" v-model="form.data.branch_name" />
          </b-form-group>
          <b-form-group label="NIK" class="mb-3 f-13 bold" disabled>
            <b-input class="f-13" v-model="form.data.nik" />
          </b-form-group>
          <b-form-group label="Jenis Keterlambatan" class="mb-3 f-13 bold">
            <b-form-select
              placeholder="Pilih Jenis Keterlambatan"
              :required="!form.data.kategori_terlambat"
              :options="opt.kategori_terlambat"
              class="w-100 f-13"
              v-model="form.data.kategori_terlambat"
            />
          </b-form-group>
          <b-form-group label="Ketegori Absen" class="mb-3 f-13 bold">
            <b-form-select
              placeholder="Pilih Kategori Absen"
              :required="!form.data.kategori_absen"
              :options="opt.kategori_absen"
              class="w-100 f-13"
              v-model="form.data.kategori_absen"
            />
          </b-form-group>
          <b-form-group label="Tanggal" class="mb-3 f-13 bold">
            <b-form-datepicker
              class="f-13"
              :required="!form.data.tgl_terlambat"
              v-model="form.data.tgl_terlambat"
              placeholder="Pilih tanggal"
            />
          </b-form-group>
          <b-form-group label="Keterangan" class="mb-3 f-13 bold">
            <b-textarea
              class="f-13"
              rows="5"
              v-model="form.data.keterangan"
              :required="!form.data.keterangan"
              placeholder="Keterangan tidak boleh kosong"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" class="p-3">
          <router-link
            class="w-100 btn btn-secondary button-sag-cancel"
            to="/presensi"
            >Batal</router-link
          >
        </b-col>
        <b-col cols="6" class="p-3">
          <b-button
            block
            class="w-100 button-sag"
            type="submit"
            :disabled="form.loading"
            >{{ form.loading ? "Memproses..." : "Ajukan" }}</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<style>
.f-12 {
  font-size: 12px;
}
.bold {
  text-transform: uppercase;
  font-weight: bold;
}
.f-13 {
  font-size: 13px;
}
.button-sag {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #539165;
  border: none;
}
.button-sag:hover {
  background-color: #539165;
}
.button-sag-cancel {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #ce5959;
  border: none;
}
.button-sag-cancel:hover {
  background-color: #ce5959;
}
</style>
<script>
import { mapGetters } from "vuex";
import axios from "@/axios";
export default {
  data() {
    return {
      form: {
        data: {
          branch_name: null,
          branch_code: null,
          nik: null,
          kategori_terlambat: null,
          kategori_absen: null,
          tgl_terlambat: null,
          keterangan: null,
        },
        loading: false,
      },
      opt: {
        kategori_terlambat: [
          {
            value: null,
            text: "Pilih Kategori Keterlambatan",
            disabled: true,
          },
          {
            value: "pydb",
            text: "Penanganan PYDB",
          },
          {
            value: "penugasan",
            text: "Penugasan",
          },
          {
            value: "kuliah",
            text: "Kuliah (Lampiran jadwal kuliah resmi dari kampus)",
          },
        ],
        kategori_absen: [
          {
            value: null,
            text: "Pilih Kategori Absen",
            disabled: true,
          },
          {
            value: "masuk",
            text: "Absen Masuk",
          },
          {
            value: "keluar",
            text: "Absen Pulang",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    setUpForm() {
      let userData = this.user.data;
      this.form.data.nik = userData.nik;
      this.form.data.branch_code = userData.branch_code;
      this.form.data.branch_name = userData.branch_name;
    },
    async doSubmit() {
      // Objek yang memetakan nama field ke nama lain yang ingin ditampilkan
      const fieldNames = {
        branch_name: "Nama Cabang",
        branch_code: "Kode Cabang",
        nik: "NIK",
        kategori_terlambat: "Kategori Terlambat",
        kategori_absen: "Kategori Absen",
        tgl_terlambat: "Tanggal Terlambat",
        keterangan: "Keterangan",
      };
      // Memeriksa apakah ada field yang bernilai null
      let nullFields = [];
      for (let key in this.form.data) {
        if (this.form.data[key] === null) {
          nullFields.push(fieldNames[key]);
        }
      }

      if (nullFields.length > 0) {
        // Ada field yang masih kosong, tampilkan pesan kesalahan
        let nullFieldsString = nullFields.join(", ");
        this.notif(
          `Harap isi semua data formulir. Anda belum mengisi: ${nullFieldsString}`,
          "Gagal",
          "error"
        );
        return; // Menghentikan proses selanjutnya
      }
      this.form.loading = true;
      let url = "/presence/keterlambatan";
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        this.form.loading = false;
        this.notif("Pengajuan Keterlambatan Berhasil", "Sukses", "success");
        this.clearForm();
        setTimeout(() => {
          this.$router.push("/presensi");
        }, 2000);
      } else {
        this.form.loading = false;
        this.notif(
          "Pengajuan Keterlambatan Gagal (Kesalahan pada server)",
          "Gagal",
          "error"
        );
      }
    },
    clearForm() {
      this.form.data = {
        branch_code: null,
        nik: null,
        sisa_cuti: 10,
        sisa_ijin: 10,
        kategori_terlambat: null,
        kategori_absen: null,
        tgl_terlambat: null,
        keterangan: null,
      };
      this.setUpForm();
    },
    notif(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-full",
      });
    },
  },
  mounted() {
    this.setUpForm();
  },
};
</script>
